import { Controller } from "stimulus"


export default class extends Controller {

    static targets = ['selectedFileToUpload', 'storeName', 'resultS3Url', 'resultUploadImg', 'linksBloc', 'imageToolbox', 'defaultNoImage']
    static values = { formToken: String }


    upload(event) {
        console.log("Upload image")

        if(this.fileNameToUpload === "") {
            return
        }

        // get presign url
        fetch('/image_direct_upload/presign?' + new URLSearchParams({
            store_name: this.storeNameTarget.value,
            file_name: this.fileNameToUpload,
        })).then(response => response.text()).then(s3_url => {
            console.log('We have a presign url')
            fetch(s3_url, {
                method: 'PUT',
                body: this.selectedFileToUploadTarget.files[0],
                headers: { 'Content-Type': 'application/octet-stream' }
            }).then( () => {
                this.resultUploadImgTarget.src = removeUrlParameters(s3_url)
                this.resultUploadImgTarget.style.visibility = "visible"

                this.linksBlocTarget.style.visibility = "visible"
                this.resultS3UrlTarget.value = removeUrlParameters(s3_url)
                this.selectedFileToUploadTarget.classList.remove("select_file_default_image")
                this.selectedFileToUploadTarget.classList.add("select_file_toolbar")

                this.imageToolboxTarget.style.visibility = "visible"

                this.defaultNoImageTarget.style.visibility = "hidden"
            })
        }).catch((error) => {
            console.error('Error catching presign url from server:', error)
        });
    }

    selectFile(event) {
        console.log("Select an image to download")
        alert("select")
        this.selectedFileToUploadTarget.click()
    }

    remove(event) {
        console.log("Remove an uploaded image")

        const formData = new FormData()
        formData.append('store_name', this.storeNameTarget.value)
        formData.append('file_name', this.fileNameToUpload)
        formData.append('authenticity_token', this.formTokenValue)

        fetch('/image_direct_upload/delete/',{ method: 'POST', body: formData }).then( () => {
            this.resultUploadImgTarget.style.visibility = "hidden"
            this.linksBlocTarget.style.visibility = "hidden"
            this.imageToolboxTarget.style.visibility = "hidden"
            this.defaultNoImageTarget.style.visibility = "visible"
            this.selectedFileToUploadTarget.classList.remove("select_file_toolbar")
            this.selectedFileToUploadTarget.classList.add("select_file_default_image")
            this.selectedFileToUploadTarget.files.value = ""
        }).catch((error) => {
            console.error('Error delete S3 object:', error)
        });
    }



    // return the checkbox element with correct id
    get fileNameToUpload() {
        return this.selectedFileToUploadTarget.value.split('\\').pop().split('/').pop()
    }

}

function removeUrlParameters(url) {
    return url.split("?")[0]
}
