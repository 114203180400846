import { Controller } from "stimulus"


export default class extends Controller {

  static targets = [ "checkbox", "explanation", "navigation", "goodanswerid", "message", "button", "linkCorrect", "linkWrong", "terminateLinkWrong", "terminateLinkCorrect"]
  static values = { score: Number }


  answer() {
    console.log("question#answer called")
    var isOneCheckboxChecked = false

    // check if the user gives an answser
    this.checkboxTargets.forEach( element => {
      if(element.checked) {
        isOneCheckboxChecked = true
      }
    })

    if(!isOneCheckboxChecked) {
      console.log("No checkbox checked")
      return
    }

    this.buttonTarget.disabled = true
    this.correctAnswerCheckbox.nextElementSibling.classList.add("bg-green-good")

    if(this.correctAnswerCheckbox.checked) {

      this.messageTarget.textContent = "Bonne réponse"

      // the last answer, we do not have links but we have a terminate link
      if(this.hasLinkCorrectTarget){
        this.linkCorrectTarget.style.visibility = "visible"
      }

      if(this.hasTerminateLinkCorrectTarget){
        this.terminateLinkCorrectTarget.style.visibility = "visible"
      }

    } else {
      this.messageTarget.textContent = "Mauvaise réponse"
      // the last answer, we do not have links
      if(this.hasLinkWrongTarget) {
        this.linkWrongTarget.style.visibility = "visible"
      }
      if(this.hasTerminateLinkWrongTarget){
        this.terminateLinkWrongTarget.style.visibility = "visible"
      }
    }

    // disable all checkbox (TODO: disable the answer button)
    this.checkboxTargets.forEach( element => {
      element.disabled = true
    })

    this.messageTarget.style.visibility = "visible";
    this.explanationTarget.style.visibility = "visible";
    this.navigationTarget.style.visibility = "visible";
  }


  update(event) {
    console.log("question#update called")
    var selectedElement = event.target

    if(!selectedElement.checked) {
      console.log("unchecked -> we do nothing")
      return
    }

    console.log("checked -> we unchecked all other checkbox")
    this.checkboxTargets.forEach( element => {
      if(element.value !=  selectedElement.value) {
        element.checked = false
      }
    })

  }



  // return the checkbox element with correct id
  get correctAnswerCheckbox() {
    var result = null
    this.checkboxTargets.forEach( element => {
      if(element.value ===  this.goodansweridTarget.id) {
        result = element
      }
    })
    return result
  }

}
