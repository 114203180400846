import { Controller } from "stimulus"



export default class extends Controller {

    static targets = [ 'forms', 'ingredientsOutput', 'ingredientInput', 'preparationInput', 'preparationsOutput', 'externalLink' ]
    static values = { nbSteps: Number }

    addIngredientLine(event) {
        var step = event.currentTarget.dataset.ingredientStep

        var lastIngredientIndex = this.getIngredientLinesPerStep(step).length-1

        console.log("Step: " + step + " - Last ingredient index:" + lastIngredientIndex)

        var lastIngredientLine = this.getIngredientLinesPerStep(step)[lastIngredientIndex]

        var cloned_ingredient_line = lastIngredientLine.cloneNode(true)

        // reset value
        //cloned_ingredient_line.querySelector("input").setAttribute("value", "")
        cloned_ingredient_line.querySelector("input").value = ""

        // we want the delete button present
        cloned_ingredient_line.querySelector("button").classList.remove("hidden")
        cloned_ingredient_line.querySelector("button").disabled = false

        lastIngredientLine.insertAdjacentElement('afterend', cloned_ingredient_line)

        event.preventDefault() //no form submit
    }


    deleteIngredientLine(event) {
        console.log("Delete ingredient line")
        var ingredientLineToRemove = event.currentTarget.parentElement.parentElement
        ingredientLineToRemove.remove()
        event.preventDefault()
    }


    addPreparationLine(event) {
        let lastPreparationIndex = this.preparationLines.length-1

        console.log("Last preparation index:" + lastPreparationIndex)

        let lastPreparationLine = this.preparationLines[lastPreparationIndex]

        let cloned_preparation_line = lastPreparationLine.cloneNode(true)

        cloned_preparation_line.querySelector("textarea").value = "" //setAttribute("value", "")
        cloned_preparation_line.querySelector("button").classList.remove("hidden")
        cloned_preparation_line.querySelector("button").disabled = false

        lastPreparationLine.insertAdjacentElement('afterend', cloned_preparation_line)

        this.renumberPreparationLines()
        event.preventDefault() //no form submit
    }

    deletePreparationLine(event) {
        console.log("Delete preparation line")
        var preparationLineToRemove = event.currentTarget.parentElement.parentElement.parentElement
        preparationLineToRemove.remove()
        this.renumberPreparationLines()
        event.preventDefault()
    }


    // Pick all ingredient and preparation forms, compile them in ingredient and preparation text fields
    saveRecipe(event){
        console.log("Save recipe")
        var ingredientsOutputText = ""
        this.ingredientInputTargets.forEach( element => {
            //alert(element)
            if(element.value != "") {
                ingredientsOutputText = ingredientsOutputText + element.value + "\r\n"
            }
        })
        //alert(ingredientsOutputText)
        this.ingredientsOutputTarget.value = ingredientsOutputText

        var preparationsOutputText = ""
        this.preparationInputTargets.forEach( element => {
            //alert(element)
            if(element.value != "") {
                preparationsOutputText = preparationsOutputText + element.value + "\r\n"
            }
        })
        this.preparationsOutputTarget.value = preparationsOutputText
    }


    // we use the same class for all ingredient lines in the same step (class_<% nb_step%>)
    getIngredientLinesPerStep(step) {
        return this.formsTarget.getElementsByClassName('class_i_' + step)
    }

    get preparationLines() {
        return this.formsTarget.getElementsByClassName('preparation_line')
    }

    renumberPreparationLines() {
        let preparation_step_numbers = this.formsTarget.getElementsByClassName('preparation_step_number')
        var step = 1
        // because preparation_step_numbers is an HTMLCollection
        Array.prototype.forEach.call(preparation_step_numbers, function(element) {
            element.textContent = step.toString()
            step = step + 1
        });
    }

    showOrHideExternalLinks(event) {

        Array.prototype.forEach.call(this.externalLinkTargets, function (element) {
            if(element.className == "hidden") {
                element.className=""
            } else {
                element.className="hidden"
            }
        });


    }

}