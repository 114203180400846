import { Controller } from "stimulus"


export default class extends Controller {

  static targets = ["fragmentIds", "initialFragmentIds", "fragmentTitle", "initialFragmentTitle", "fragmentType", "finalJson", "fragmentText", "initialFragmentText", "fragmentLegend", "initialFragmentLegend"]


  addId(event) {
    console.log("addid called")
    const itemId = event.currentTarget.dataset.itemId;
    const itemType = event.currentTarget.dataset.itemType;
    const parser = new URL(window.location)
    const c_comma = this.fragmentIdsTarget.value == '' ? "" : ", "

    switch (this.fragmentTypeTarget.value) {
      case 'recette':
        parser.searchParams.set("ids", "[" + itemId + "]")
        break

      case 'selection':
        parser.searchParams.set("ids", "[" + itemId + "]")
        break

      case 'quiz':
        parser.searchParams.set("ids", "[" + itemId + "]")
        break

      case 'article':
        parser.searchParams.set("ids", "[" + itemId + "]")
        break

      case 'recettes_techniques' :
        parser.searchParams.set("ids", "[" + this.fragmentIdsTarget.value + c_comma + "{\"id\":" + itemId + "}]")
        break

      case 'voir_aussi':
        parser.searchParams.set("ids", "[" + this.fragmentIdsTarget.value + c_comma + "{\"id\":" + itemId + ",\"model\":\"" + itemType + "\"" + "}]")
        break

      default:
        parser.searchParams.set("ids", "[" + this.fragmentIdsTarget.value + c_comma + itemId + "]")
    }

    window.location = parser.href
  }

  changeFragmentType(event) {
    console.log("changeFragmentType called")
    const parser = new URL(window.location)
    parser.searchParams.set("type", this.fragmentTypeTarget.value)
    parser.searchParams.set("ids", "[]")
    window.location = parser.href
  }


  changeFragmentTitle(event) {
    console.log("changeFragmentTitle called")
    if(this.initialFragmentTitleTarget.value !== this.fragmentTitleTarget.value) {
      const parser = new URL(window.location)
      parser.searchParams.set("title", this.fragmentTitleTarget.value)
      window.location = parser.href
    }
  }

  changeFragmentIds(event) {
    console.log("changeFragmentIds called")
    if(this.initialFragmentIdsTarget.value !== this.fragmentIdsTarget.value) {
      const parser = new URL(window.location)
      parser.searchParams.set("ids", "[" + this.fragmentIdsTarget.value + "]")
      window.location = parser.href
    }
  }

  changeFragmentText(event) {
    console.log("changeFragmentText called")

    if(this.initialFragmentTextTarget.value !== this.fragmentTextTarget.value) {
      const parser = new URL(window.location)
      parser.searchParams.set("text", encodeURIComponent(this.fragmentTextTarget.value))
      window.location = parser.href
    }
  }
  changeFragmentLegend(event) {
    console.log("changeFragmentLegend called")

    if(this.initialFragmentLegendTarget.value !== this.fragmentLegendTarget.value) {
      const parser = new URL(window.location)
      parser.searchParams.set("legend", encodeURIComponent(this.fragmentLegendTarget.value))
      window.location = parser.href
    }
  }


}
