import { Controller } from "stimulus"


export default class extends Controller {

    static targets = [ 'ingredientsBloc', 'nbPersons' ]
    static values = { nbPersons: Number, initialValues: Array }


    incrementNbPersons(event) {
        console.log("Increment number of persons. Nb persons=" + this.nbPersonsValue ); //+ nbPersons + " to " + (nbPersons+1) )
        this.nbPersonsValue = this.nbPersonsValue + 1
        this.updateQuantities(this.nbPersonsValue)
    }

    decrementNbPersons(event) {
        console.log("Decrement number of persons");
        if(this.nbPersonsValue == 1) {
            return;
        }
        this.nbPersonsValue = this.nbPersonsValue - 1
        this.updateQuantities(this.nbPersonsValue)
    }

    updateQuantities(newNbPersonsValue) {
        console.log("Update quantities to " + newNbPersonsValue + " persons");
        let ingredient_amounts = this.ingredientsBlocTarget.getElementsByClassName('ingredient_amount')
        let initValues = this.initialValuesValue
        var index = 0
        Array.prototype.forEach.call(ingredient_amounts, function(element) {
            let amountForOnePerson = initValues[index]
            let newAmount = amountForOnePerson*newNbPersonsValue
            element.textContent = roundToTwo(newAmount)
            index ++
        });
        this.nbPersonsTarget.textContent = newNbPersonsValue.toString() + (newNbPersonsValue==1?' personne':' personnes')
    }
}

// function myParseFloat(amountStr) {
//     if(amountStr.includes('/')){
//         console.log("we have a fraction")
//         let amountArray = amountStr.split("/");
//         return parseInt(amountArray[0])/parseInt(amountArray[1])
//     }
//     return parseFloat(amountStr)
// }

function roundToTwo(num) {
    return +(Math.round(num + "e+1")  + "e-1");
}